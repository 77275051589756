.blockoverlay {
  z-index: 10;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgb(0, 0, 0);
  opacity: 0.05;
  cursor: wait;
  position: fixed;
}

.blockpage {
  z-index: 1011;
  position: fixed;
  padding: 0px;
  margin: 0px;
  width: 168px;
  top: 50%;
  left: 50%;
  text-align: center;
  color: rgb(0, 0, 0);
  border: 0px;
  cursor: wait;
}

.blockui {
  margin-left: -84px;
}
