.kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled .kt-wrapper {
  padding-top: 60px !important;
}
.kt-widget17 .kt-widget17__stats {
  margin: unset;
  width: 100%;
}

.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item {
  padding-left: 2rem;
  border-radius: 0.5rem;
}

.kt-portlet {
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}

.color-blue {
  color: #5867dd;
}
.color-pink {
  color: #fd397a;
}
.color-green {
  color: #0abb87;
}
.color-yellow {
  color: #ffb822;
}

.detalle-convocatoria {
  margin-left: 0.3rem;
  flex-grow: 1;
}

.indicadores {
  margin-bottom: 1rem;
}

.b1 {
  display: flex;
  flex-flow: column;  
}

.b2 {
  display: flex;
  flex-flow: column;
  margin-left: 0.3rem;
}

.c1 {
  flex: 2;
  display: flex;
  flex-flow: column;  
}

.c2 {
  flex: 1;
  display: flex;
  flex-flow: column;
  margin-left: 1rem;
}

.c3 {
  flex: 3;
  display: flex;
  flex-flow: column;
}

.c4 {
  flex: 1;
  margin-left: 1rem;
}

.contrato-avance {
  flex: 2;
}

.c3__c2 {
  flex: 1;
  margin-left: 1rem;
  display: flex;
  flex-flow: column;
}

.progreso-cv {
  flex: 1;
}

.contrato {
  margin: 1rem;
}

.b3 {
  margin-left: 0.3rem;
  display: flex;
  flex-flow: column;
}

.consultores-grado {
  flex: 1;
}

.contrato-responsable {
  flex: 1;
  margin-left: 1rem;
}

.actividad-reciente .kt-portlet__body {
  padding: 0 1.5rem;
  padding-bottom: 0.5rem;
}

.actividades-recientes__item {
  display: flex;
  align-items: center;
}

.actividades-recientes__item .actividades-recientes__nombre {
  flex-grow: 1;
}

.actividades-recientes__item .actividades-recientes__imagen img {
  width: 4rem;
  border-radius: 50%;
}
canvas {
  width: 100% !important;
}
