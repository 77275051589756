.ck-editor__editable_inline {
    min-height: 150px;
}

.pagination{
    justify-content: center;
}

.pagination li
{
    border-radius : 3px;
    cursor : pointer;
    padding : 7px 10px;
    margin-left: 0.4rem;
    height: 2.25rem;
    min-width: 2.25rem;
}

.pagination li.active{
    background-color : #0abb87;
}

.pagination li.active a
{
    color : white
}

thead[data-test="table-foot"]
{
    display: none;
}

thead[data-test="datatable-head"] tr th
{
    font-weight: bold;
}

.show-profile
{
    position: absolute;
    transform: translate3d(586px, 64px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
}

.show-export
{
    transform: translate3d(-166px, 32px, 0px);
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    will-change: transform;
}

/* AUTOSUGGEST */

.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    color: #495057;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 1rem;
    border : 1px solid #e2e5ec;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  /* STYLES REACTRANGEPICKER AIRBNB */
  /*.CalendarDay__selected_span {
    background: #82e0aa;
    color: white;
    border: 1px solid #0abb87;
  }
  
  .CalendarDay__selected {
    background: #0abb87;
    color: white;
  }
  
  .CalendarDay__selected:hover {
    background: #5d78ff;
    color: white;
  }
  .DateRangePickerInput__withBorder_2
  {
    border: 1px solid #e2e5ec;
    border-radius: 4px;
  }*/

  .bVwnqy div:first-child, .cYyUNK div:first-child
  {
    white-space: normal!important;
  }